<template>
  <v-app-bar color="primary" prominent>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title>DataLake Search</v-toolbar-title>
  </v-app-bar>
  <nav-drawer v-model="drawer" />
</template>

<script setup>
import { ref } from "vue";
import NavDrawer from "@/components/NavDrawer.vue";

const drawer = ref(false);
</script>
