import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/", redirect: { path: "/search-raw" } },
  {
    path: "/search-raw",
    name: "Search Raw",
    meta: { title: "Search Raw" },
    component: () => import("@/views/SearchRawView.vue"),
  },
  {
    path: "/search-index",
    name: "Search Index",
    meta: { title: "Search Index" },
    component: () => import("@/views/SearchIndexView.vue"),
  },
  {
    path: "/config",
    name: "Config",
    meta: { title: "Config" },
    component: () => import("@/views/ConfigView.vue"),
  },
  {
    path: "/docs",
    name: "Documentation",
    meta: { title: "Documentation" },
    component: () => import("@/views/docs/DocsLayout.vue"),
    children: [
      {
        path: "search-raw",
        name: "Search Raw Docs",
        meta: { title: "Search Raw Docs" },
        component: () => import("@/views/docs/SearchRawDocs.vue"),
      },
    ],
  },
  { path: "/:pathMatch(.*)", component: () => import("@/views/NotFoundView.vue") },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
