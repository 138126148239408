<template>
  <v-navigation-drawer v-model="model" temporary>
    <v-list>
      <template v-for="route in routes" :key="route.name">
        <v-list-group v-if="route.children && route.children.length" :value="route.name">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" :title="route.name"></v-list-item>
          </template>
          <v-list-item
            v-for="childRoute in route.children"
            :key="childRoute.name"
            :to="`${route.path}/${childRoute.path}`"
            @click="model = false"
            link
          >
            <v-list-item-title>{{ childRoute.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :to="route.path" @click="model = false" link>
          <v-list-item-title>{{ route.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

const model = defineModel();
const router = useRouter();

const routes = computed(() => router.options.routes.filter((route) => route.name && route.path !== "/:pathMatch(.*)"));
</script>
