import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import "vuetify/styles";

export default createVuetify({
  theme: {
    defaultTheme: "dark",
    themes: {
      light: {
        colors: {
          marker: "#e0ea3a",
          ["on-marker"]: "#000000",
        },
      },
      dark: {
        colors: {
          marker: "#e0ea3a",
          ["on-marker"]: "#000000",
        },
      },
    },
  },
  defaults: {
    global: {
      ripple: false,
    },
    VSelect: {
      variant: "outlined",
      transition: false,
    },
    VList: {
      density: "compact",
      transition: false,
    },
    VTextField: {
      density: "compact",
      variant: "outlined",
      transition: false,
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});
